.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

@font-face {
  font-family: 'Glacial';
  src: local('Glacial'), url(./fonts/GlacialIndifference-Regular.otf) format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Lobster';
  src: local('Lobster'), url(./fonts/Lobster-Regular.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Sriracha';
  src: local('Sriracha'), url(./fonts/Sriracha-Regular.ttf) format('truetype');
  font-display: swap;
}

.StripeElement {
  width: 100%;
}